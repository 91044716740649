import React, { useEffect } from 'react'
import background from '../assets/images/features-background.png'
import SiltaDao from '../assets/images/silta-dao.png'
import DynamicNft from '../assets/images/dynamic-nft.png'
import WindTurbines from '../assets/images/wind-turbines.png'
import RealWorldAssets from '../assets/images/city-on-island.png'
import { isMobile } from '../helpers'

// const siltaWhitePaperUrl = '/whitepaper'

const SiltaFeatures = () => {

  useEffect(() => {

    if (isMobile()) {
      const bg1 = document.querySelector('.bg-dao');
      const bg2 = document.querySelector('.bg-nft');
      const bg3 = document.querySelector('.bg-wind');
      const bg4 = document.querySelector('.bg-assets');

      const illus1 = document.querySelector('.illustration-dao');
      const illus2 = document.querySelector('.illustration-nft');
      const illus3 = document.querySelector('.illustration-wind');
      const illus4 = document.querySelector('.illustration-assets');

      bg1.classList.add('features-bg')
      illus1.classList.add('features-illustrations')
      bg2.classList.add('features-bg')
      illus2.classList.add('features-illustrations')
      bg3.classList.add('features-bg')
      illus3.classList.add('features-illustrations')
      bg4.classList.add('features-bg')
      illus4.classList.add('features-illustrations')
    }

    window.addEventListener('scroll', () => {
      const bg1 = document.querySelector('.bg-dao');
      const bg2 = document.querySelector('.bg-nft');
      const bg3 = document.querySelector('.bg-wind');
      const bg4 = document.querySelector('.bg-assets');

      const illus1 = document.querySelector('.illustration-dao');
      const illus2 = document.querySelector('.illustration-nft');
      const illus3 = document.querySelector('.illustration-wind');
      const illus4 = document.querySelector('.illustration-assets');


      const rectBg1 = bg1.getBoundingClientRect();
      const rectBg2 = bg2.getBoundingClientRect();
      const rectBg3 = bg3.getBoundingClientRect();
      const rectBg4 = bg4.getBoundingClientRect();


      const isInViewportBg1 = rectBg1.top >= 0 && rectBg1.bottom <= (window.innerHeight*1.5 || document.documentElement.clientHeight*1.5)
      // rectBg1.left >= 0 &&
      // rectBg1.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      // rectBg1.right <= (window.innerWidth || document.documentElement.clientWidth);

      const isInViewportBg2 = rectBg2.top >= 0 && rectBg2.bottom <= (window.innerHeight*1.5 || document.documentElement.clientHeight*1.5)
      // rectBg2.left >= 0 &&
      // rectBg2.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      // rectBg2.right <= (window.innerWidth || document.documentElement.clientWidth);

      const isInViewportBg3 = rectBg3.top >= 0 && rectBg3.bottom <= (window.innerHeight*1.5 || document.documentElement.clientHeight*1.5)
      // rectBg3.left >= 0 &&
      // rectBg3.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      // rectBg3.right <= (window.innerWidth || document.documentElement.clientWidth);

      const isInViewportBg4 = rectBg4.top >= 0 && rectBg4.bottom <= (window.innerHeight*1.5 || document.documentElement.clientHeight*1.5)
      // rectBg4.left >= 0 &&
      // rectBg4.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      // rectBg4.right <= (window.innerWidth || document.documentElement.clientWidth);


      if(isInViewportBg1) {
        bg1.classList.add('features-bg')
        illus1.classList.add('features-illustrations')
      }
      if (isInViewportBg2){
        bg2.classList.add('features-bg')
        illus2.classList.add('features-illustrations')
      }
      if (isInViewportBg3){
        bg3.classList.add('features-bg')
        illus3.classList.add('features-illustrations')
      }
      if (isInViewportBg4){
        bg4.classList.add('features-bg')
        illus4.classList.add('features-illustrations')
      }
    }
   )
  }, [])

  return (
    <section className="section-features">
      <div className="container">
        <div className="section-features-content" >
        <div className='row'>
            <div className='col-xs-12 col-md-6 left-row'>
              <div>
                <div className='img-container'>
                    <img src={background}
                    alt="pruple background"
                    className='bg-dao'
                    />
                    <img src={DynamicNft}
                    className="illustration-nft"
                    alt="NFT illustration"
                    />
                </div>
              </div>
              <div className='card-features'>
                <p className='card-title'>
                  Revolutionary due diligence tools<span className='purple-title'></span>
                </p>
                <p className='card-text'>
                Silta helps financiers to assess the viability of infrastructure projects through a professional, fast, and cost-effective due diligence process. Silta Score quantifies the due diligence rating and the impact of proposed projects.
                </p>
              </div>
            </div>
            <div className='col-xs-12 col-md-6 right-row'>
            <div className='img-container'>
                <img className='bg-nft' src={background} alt="pruple background"
                  />
                 <img src={SiltaDao}
                  className="illustration-dao"
                  alt="DAO illustration"
                  />
            </div>
            <div className='card-features'>
              <p className='card-title'>
              Access to finance globally<span className='purple-title'></span>
              </p>
              <p className='card-text'>
              Silta gives borrowers access to a global network of financiers. Financing opportunities are showcased in the Silta App with project viability and expected impact already assessed, helping financiers to make investment decisions.
              </p>
            </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-xs-12 col-md-6 left-row'>
            <div className='img-container'>
             <img className='bg-wind'
                src={background} alt="pruple background"
            />
            <img src={WindTurbines}
            className="illustration-wind"
            alt="Wind turbines illustration"
            />
            </div>
            <div className='card-features'>
              <p className='card-title'>
              Transparency throughout<span className='purple-title'></span>
              </p>
              <p className='card-text'>
              To help ensure the proper use of funds, Silta offers the ability to track projects during the loan tenor. Regular reporting and monitoring provides the financiers with transparency and reassurance.
              </p>
            </div>
            </div>
            <div className='col-xs-12 col-md-6 right-row'>
            <div className='img-container'>
                <img className='bg-assets'
                src={background} alt="pruple background"/>
                <img src={RealWorldAssets}
                className="illustration-assets"
                alt="Real world assets illustration"
                />
            </div>
            <div className='card-features'>
              <p className='card-title'>
              Rewarding impact<span className='purple-title'></span>
              </p>
              <p className='card-text'>
              Tracking of borrowers' realised and verified impact leads to a more efficient and fair ecosystem. Silta rewards provide an additional incentive for borrowers to achieve the expected impact.
              </p>
            </div>
            </div>
        </div>
        </div>
        <div className='row download-whitepaper-container'>
            <div className='btn-container'>
            {/* <a href={siltaWhitePaperUrl} className="btn btn-primary btn-hero" target="_blank" rel="noreferrer">
                DOWNLOAD THE WHITEPAPER
                <img src={DownloadIcon} alt='download whitepaper' className='download-icon ml-2' />
            </a> */}
            </div>
          </div>
      </div>
    </section>
  )
}

export default SiltaFeatures