import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import LeadSection from '../components/leadSection'
import SiltaFeatures from '../components/siltaFeatures'
import Contact from '../components/contact'
import NewsAndBlog from '../components/newsAndBlog'
import Newsletter from '../components/newsletter'
import SeenOn from '../components/seenOn'
import ScrollToTop from '../components/ScrollToTop'


export default function Home() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <Layout>
    <Seo title="Silta" />
    <ScrollToTop />
    <div className='fixed-header'>
      <Hero />
    </div>
    <LeadSection />
    <SiltaFeatures />
    <NewsAndBlog />
    <SeenOn />
    <Contact />
    <Newsletter />
  </Layout>
}
